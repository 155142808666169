/* Language code splitter */

/*
da -> da
da-DA -> da
en-US -> en
 */
import {useParams} from "react-router-dom";
import {AppConfig} from "../utils/AppConfig";
import {EXTENDEDMUNICIPALITIES} from "./kommuner/ExtendedMunicpalities";

function getSimpleLanguageCode(code: string) {

    if (code.indexOf('-') !== -1) {
        return code.split("-")[0];
    } else {
        return code;
    }
}

export default getSimpleLanguageCode;
