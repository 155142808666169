import * as React from "react";
import {useContext, useEffect, useRef} from "react";
import MapContext from "../../contexts/MapContext";
import * as ol from "ol";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import "swiper/css";

import styles from "./MultipleFacilityBadge.module.css";
import {AppConfig} from "../../utils/AppConfig";

import SwiperSlideInnerWrapper from "./SwiperSlideInnerWrapper";

const MultipleFacilityBadge: React.FC = () => {

    const {getState} = useContext(MapContext);
    const swiperRef = useRef<SwiperRef>(null);

    /**
     * Swiper and its content rendered.
     */
    function handleSwiper(swiper: any) {
        let mol = getState().tappedFeatures[0]?.getProperties().objekt_id;
        getState().setFeatureIdInMapOverlay(mol);
    }

    function getSlides()  {
        // console.log('getSlides(): ', getState().tappedFeatures);
        return getState().tappedFeatures.map((f: ol.Feature) =>
            <SwiperSlide className="list-item"
                         key={f.getProperties().objekt_id}>
                <SwiperSlideInnerWrapper feature={f}></SwiperSlideInnerWrapper>
            </SwiperSlide>
        );
    }

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.update();
        }
    }, []);

    return (
        <Swiper ref={swiperRef}
                autoHeight={true}
                touchEventsTarget={'wrapper'}
                watchSlidesProgress={true}
                className={styles.swiper}
                onSwiper={(swiper) => {
                    setTimeout(() => {
                        console.log('onswiper');
                        swiper.update()
                    }, 200);
                }}
                spaceBetween={"10px"}
                slidesPerView={AppConfig.swipers.detail.slidesPerView}>
            {getSlides()}
        </Swiper>
    )
}

export default MultipleFacilityBadge;
