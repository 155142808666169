import {ICategoryItem, IThemegroup} from "../../../interfaces/ICategoryItem";
import {useContext, useEffect, useState} from "react";
import MapContext from "../../../contexts/MapContext";

import styles from "./FilterCategoryIndicators.module.css";
import flattenArray from "../flatten";

interface FilterCategoryIndicatorsProps {
    category: ICategoryItem
}

/**
 * FilterCategoryIndicators is a line of dots that indicate selected / not selected filters on the top of the
 * Filter Harmonium.
 *
 * @param category
 * @constructor
 */
const FilterCategoryIndicators: React.FC<FilterCategoryIndicatorsProps> = ({category}) => {

    const {getState} = useContext(MapContext);

    const [result, setResult] = useState<boolean[]>([]);

    /*
    Change the appearance of the dots according to filter state.
     */
    useEffect(() => {

        // A List of group IDs
        let groupIDs:number[] = [];

        let themes =  flattenArray(category.themes);

        let nt = category.themes.map((r: number | IThemegroup) => {
            if (typeof r === 'number') {
                return r
            } else {
                groupIDs.push(r.number)
                return r.themes
            }
        })

        const themeFilter = getState().themeFilter;

        /*
        // TODO: subsitute with flatten.ts
        let flattenedThemeFilter = themeFilter.map((m: number) => {
            if (groupIDs.indexOf(m)> -1) {
                // we have a group, lets resolve it
                return category.themes.map((r: number | IThemegroup) => {
                    if (typeof r === 'number') {
                    } else {
                        // IThemegroup
                        return r.themes
                    }
                }).filter(Boolean).flat() as number[] || [];
            } else {
                return m;
            }
        }).flat() as number[] || [
         */

        let flattenedThemeFilter: number[] = [];

        let x: boolean[] = [];
        themes.forEach(t => {
            if (flattenArray(themeFilter).indexOf(t) > -1) {
                x.push(true)
            } else {
                x.push(false)
            }
        })

        if (themes.length === 0) {
            setResult(new Array(themes.length).fill(true));
        } else {
            setResult(x);
        }

    }, [getState, getState().themeFilter]);

    function getChecked(c: boolean) {
        return c ? styles.checked : styles.notChecked
    }

    function getDots() {
        return result.map((bool, index) => (
            <div className={`${styles.indicator} ${getChecked(bool)}`}
                 key={`${category.label}-${bool}-${index}`}></div>
        ));
    }

    return (
        <div className={styles.indicators}>{getDots()}</div>
    );

    function groupIDForThemeID(themeID: number): number {
        // foreach()
        return 0
    }

}

export default FilterCategoryIndicators;
