import * as React from "react";
import {useContext, useEffect, useState} from "react";
import MapContext from "../../../contexts/MapContext";
import {IThemegroup} from "../../../interfaces/ICategoryItem";
import FilterListItem from "../FilterListItem/FilterListItem";
import styles from "./AccordionThemeItem.module.css";

function removeElementFromArray(array: any[], element: any) {
    const index = array.indexOf(element, 0);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}

interface AccordionThemeItemProps {
    category: (IThemegroup | number)
}

const AccordionThemeItem: React.FC<AccordionThemeItemProps> = ({
                                                                   category
                                                               }) => {
    const {getState} = useContext(MapContext);
    const [active, setActive] = useState<boolean>(true);
    const dynamicClassName = active ? 'active' : 'inactive';

    // const [catnr, setCatnr] = useState<number>(0);

    function toggle() {
        let tf = getState().themeFilter;
        const inList = tf.indexOf(category) > -1;
        if (inList) {
            // remove
            tf = removeElementFromArray(tf, category);
        } else {
            // add
            tf.push(category)
        }

        // This is a little hack, without it the useEffect()[themeFilter] won't work?!
        getState().setThemeFilter(tf.map((t: number) => t));
    }

    /**
     * Listen to the themeFilter and adapt 'active'
     */
    useEffect(() => {
        if (getState().themeFilter.length === 0) {
            // Nothing is selected
            setActive(true)
        } else {
            // Some theme is active
            if (getState().themeFilter.indexOf(category) === -1) {
                setActive(false)
            } else {
                setActive(true);
            }
        }
    }, [getState()]);

    return (
        <div className={`${styles.accordionThemeItem} ${styles.padding} ${styles[dynamicClassName]}`}
             slot="content"
             onClick={() => toggle()}>
            <FilterListItem category={category}></FilterListItem>
        </div>
    )
};
export default AccordionThemeItem;
