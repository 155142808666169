import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";

import {IonAccordion, IonAccordionGroup, IonItem, IonLabel} from "@ionic/react";
import FilterChevronDown from '../../../assets/filter-chevron-down.svg';

import {ICategoryItem, IThemegroup} from "../../../interfaces/ICategoryItem";
import AccordionThemeItem from "../AccordionThemeItem/AccordionThemeItem";
import {AppConfig} from "../../../utils/AppConfig";
import MapContext from "../../../contexts/MapContext";
import FilterCategoryIndicators from "../FilterCategoryIndicators/FilterCategoryIndicators";

import './simple.css';
import styles from './FilterListComponent.module.css';
import {Trans, useTranslation} from "react-i18next";
import i18n from "i18next";
import getSimpleLanguageCode from "../../../services/Helpers";

function getKeyFromT(t: (IThemegroup | number)): number {
    let r;
    if (typeof t === "number") {
        r = t;
    } else {
        r = t.number;
    }
    return r;
}

function getThemesForCategory(f:any) {
    return f.themes.map((t: (IThemegroup | number)) =>
        <AccordionThemeItem category={t} key={getKeyFromT(t)}></AccordionThemeItem>
    )
}

const FilterListComponent: React.FC = () => {

    const firstItemSlidingRef = useRef(null);
    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

    const [language, setLanguage] = useState<string>("da");

    const {getState} = useContext(MapContext);

    const { t, i18n } = useTranslation();

    useEffect(() => {

        const nativeEl = accordionGroup.current;

        if (nativeEl) {
            nativeEl.value = getLabel(AppConfig.categories[0]);
        }
    }, []);

    /*
    Get the label for a category.
    Fallback to Danish is German or English is not set.
     */
    function getLabel(categoryItem: ICategoryItem): string {

        const sL = getSimpleLanguageCode(i18n.language);

        if (sL === 'da') {
            return categoryItem.label
        } else if (sL === 'de') {
            return categoryItem.label_de || categoryItem.label
        } else {
            return categoryItem.label_gb || categoryItem.label
        }

    }

    const renderListItems = () => {
        {/* {getThemesForCategory(f).length > 0 && getThemesForCategory(f).length} */}
        return AppConfig.categories.map((f: ICategoryItem) =>
            <IonAccordion value={getLabel(f)}
                          key={getLabel(f)}
                          toggleIcon={FilterChevronDown}
                          ref={firstItemSlidingRef}>
                <IonItem slot="header" color="light">
                    <IonLabel> {getLabel(f)}</IonLabel>
                    <FilterCategoryIndicators category={f}></FilterCategoryIndicators>
                </IonItem>
                {getThemesForCategory(f)}
            </IonAccordion>
        );
    };

    return (
    <>
        <div className={styles.topSpacer}>
            <Trans i18nKey="filterInstruction"></Trans>
        </div>
        <IonAccordionGroup ref={accordionGroup}>
            {renderListItems()}
        </IonAccordionGroup>
    </>
    )
}

export default FilterListComponent;
