import {cats} from "./setup";
import {ICategoryItem} from "../interfaces/ICategoryItem";
import {EFilterName} from "../services/IconService";

interface IModalSetting {
    initialBreakPoint: number,
    breakpoints: number[]
}

interface IAppConfig {
    languageOn: boolean
    srs: number
    kommunenr: number
    filterName: EFilterName
    off_kode: number // Publication name

    // Only points of these types will be retrieved from the GeoFA service
    FACILITY_POINTS: number[];
    FACILITY_LINES: number[];
    FACILITY_POLYGONS: number[];

    // Set this one if you run the bbox and need to filter anything outside the kommunegrænse away.
    // Like so: '/blabla.json' NB: takes only json so rename geojsons. Needs to be in 4326 and put it into /public
    kommunepolygonFileName?: string

    // Use the bbox method to retrieve data
    bbox: number[][] | boolean
    apiBaseUrl: string
    photoBaseUrl: string
    map: {
        zoomToExtent?: boolean,
        initialMapCenter: number[],
        initialMapZoom: number,
        hitTolerance: number,
        enableRotation: boolean
    }
    categories: ICategoryItem[]
    modals: {
        filter: IModalSetting,
        list: IModalSetting,
        mapClick: IModalSetting
    },
    swipers: {
        detail: {
            slidesPerView: number
        },
        photos: {
            slidesPerView: number
        }
    },
    distanceTappedLocation: number          // The distance between the current location and the one we have
                                            // tapped the orientation FAB last
}


/*
TODO: Temporært fjernet
Idrætsanlæg 4031
Idrætshal 4011
Svømmehal 4021
Ketchersportsanlæg 4251
 */

export const AppConfig: IAppConfig = {

    languageOn: true,
    kommunenr: 550,
    filterName: EFilterName.beliggenhedskommune,
    off_kode: 1,
    FACILITY_POINTS: [1022, 1031, 1012, 1041, 1051, 1061, 1062, 1072, 1082, 1091, 1102, 1182, 1191,
        1122, 1132, 1142, 1152, 1201, 1211, 1222, 1232, 1291, 1302, 1331, 1351, 2012, 2022, 2031, 2041, 2051, 2062,
        2072, 2082, 2092, 2171, 2132, 2201, 2223, 3012, 3031, 3041, 3051, 3061, 3071, 3091, 4041, 4051,
        4081, 4101, 4111, 4121, 4132, 4142, 4222, 4231, 4322, 4272, 4071, 4161, 4191, 4411, 5011, 5021,
        5031, 5051, 5061, 5072, 5082, 5102, 6011, 6052, 6062, 6042, 9981],
    FACILITY_LINES: [],
    FACILITY_POLYGONS: [],
    kommunepolygonFileName: './550-simpilified-100m-900913.json',
    srs: 3857,
    bbox: false,
    apiBaseUrl: "https://geofa.geodanmark.dk/api/v2/sql/fkg",
    photoBaseUrl: "https://mapcentia-www.s3-eu-west-1.amazonaws.com/fkg/",
    map: {
        zoomToExtent: false,
        initialMapCenter: [8.863673188688109, 54.939638767894486],
        initialMapZoom: 12,
        hitTolerance: 5,
        enableRotation: false
    },
    categories: cats,
    modals: {
        filter: {
            initialBreakPoint: 0.7,
            breakpoints: [0, 0.7, 1]
        },
        list: {
            initialBreakPoint: 0.4,
            breakpoints: [0, 0.4, 1]
        },
        mapClick: {
            initialBreakPoint: 0.4,
            breakpoints: [0, 0.4, 1]
        },
    },
    swipers: {
        detail: {
            slidesPerView: 1.1
        },
        photos: {
            slidesPerView: 1.1
        }
    },
    distanceTappedLocation: 100

}
