/* TODO: Deprecate please */

import * as React from "react";
import FacilityItem from "../FacilityItem/FacilityItem";
import Facility from "../Facility/Facility";
import {useState} from "react";

interface SingleFacilityBadgeProps {
    objectId: string
}

const SingleFacilityBadge: React.FC<SingleFacilityBadgeProps> = ({
                                                                     objectId,
})=> {

    const [loading, setLoading] = useState(true);

    function dynamicStyleFunction() {
        return '';
    }

    function handleCallback (msg: boolean) {
        setLoading(msg);
    }

    return (
        <>

            <FacilityItem objectId={objectId}
                          loading={loading}></FacilityItem>

            <Facility parentCallback={handleCallback}
                      className={dynamicStyleFunction()}
                      objectId={objectId} ></Facility>
        </>
    )
}

export default SingleFacilityBadge;
