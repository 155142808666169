import * as React from "react";
import {IonCol, IonContent, IonHeader, IonPage, IonRow} from "@ionic/react";
import TheMap from "../../components/TheMap/TheMap";

import FacilityList from "../../components/FacilityList/FacilityList/FacilityList";
import FilterListComponent from "../../components/Filter/FilterListComponent/FilterListComponent";

import styles from './WidePage.module.css';

const WidePage: React.FC = () => {

    return (
        <IonPage>
            <div className={styles.backdrop}></div>
            <IonHeader>
                NQ Outdoor Recreation Excitement App
            </IonHeader>
            <IonContent>
                <IonRow>
                    <IonCol>
                        <TheMap mapId={'map'}></TheMap>
                    </IonCol>
                    <IonCol>
                        <FilterListComponent></FilterListComponent>
                    </IonCol>
                    <IonCol>
                        <FacilityList></FacilityList>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    )
}

export default WidePage;
