import * as React from "react";
import {useContext, useEffect, useMemo, useState} from "react";
import PhotoCarousel from "./PhotoCarousel/PhotoCarousel";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import {EGeoType} from "../../services/IconService";
import {Link, useParams} from "react-router-dom";
import {Feature} from "ol";
import {GeoJSON} from "ol/format";
import GeoFAService from "../../services/GeoFAService";

import GeoFaWidget from "./GeoFaWidget";

import styles from "./Facility.module.css";
import FacilityMap from "./FacilitylMap/FacilityMap";
import MapContext from "../../contexts/MapContext";
import {useSwiper} from "swiper/react";
import {Trans, useTranslation} from "react-i18next";
import {getKommunenr, IRouterParams} from "../TheMap/TheMap";

interface FacilityProps {
    objectId: string,
    className?: any,
    isObject?: boolean,
    parentCallback: (loaded: boolean) => void;
}

const Facility: React.FC<FacilityProps> = ({
                                               objectId, className,
                                               isObject,
                                               parentCallback}) => {

    const {getState} = useContext(MapContext);
    let {kommuneGUID} = useParams<IRouterParams>();

    const swiper = useSwiper();
    const {t} = useTranslation();

    const cls = `${isObject ? styles.isObject : styles.isNotObject}`;

    const [feature, setFeature] = useState<Feature>(
        new Feature()
    );

    // const [geoType, setGeotype] = useState<EGeoType>()
    const [loading, setLoading] = useState(true);
    const [showMap, setShowMap] = useState(false)
    const [oI, setOI] = useState<string>('')

    const featureProperties = useMemo<any>(() => {
        return feature?.getProperties() || {}
    }, [feature]);

    /*
    We check whether the ID comes via the context or the parameter
     */
    useEffect(() => {

        if (!objectId) {
            setOI(getState().featureIdInMapOverlay);
        } else {
            setOI(objectId)
        }

    }, [getState]);

    /*
    Let's get the full data of the feature
    if the geotype param is not given, we have to lookup which kind of Feature we have
   */
    useEffect(() => {

        const gAs = new GeoFAService(getKommunenr(kommuneGUID));

        /*
        Retrieve a feature fromm the backend.
         */
        async function fetchFeature() {

            // Look up the feature type by GUID
            const foundElement = getState().allFeatures.find((element:any) => element.getProperties().objekt_id === oI);
            const temakode = foundElement.getProperties().temakode;

            // const geoType = await gAs.getGeoFAFeatureType(oI);
            let dL: any = {};

            // TODO: here, get from Store
            if (temakode === 5800) {
                dL = await gAs.getSingleFeatureFromgeoFA(oI, EGeoType.Point);
            } else if (temakode === 5802) {
                dL = await gAs.getSingleFeatureFromgeoFA(oI, EGeoType.Line);
            } else if (temakode === 5801) {
                dL = await gAs.getSingleFeatureFromgeoFA(oI, EGeoType.Polygon);
            }

            let olFeature = new GeoJSON().readFeature(dL.features[0]);
            setFeature(olFeature);

            setShowMap(true);
            swiper.update()
            setLoading(false)

        }

        if (oI) {
            let startTime = performance.now()
            fetchFeature().catch((e) => {
                let endTime = performance.now()
                console.log('Load time: ', endTime - startTime);
                parentCallback(false)
            });
        }

    }, [oI])

    return (
        <>  <IonGrid className={cls}>
            <IonRow>
                <PhotoCarousel objectId={oI} properties={featureProperties}></PhotoCarousel>
            </IonRow>
        </IonGrid>
            <IonGrid className={cls}>

                {featureProperties.beskr &&
                    <IonRow>
                        <GeoFaWidget properties={featureProperties}
                                     typeCodes={[]}
                                     field={'beskr'}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {featureProperties.beskrivels &&
                    <IonRow>
                        <IonCol class={styles.paddingLeftNull}>
                            <GeoFaWidget properties={featureProperties}
                                         typeCodes={[6052]}
                                         field={'beskrivels'}
                                         de_field={'d_k_beskr'}
                                         en_field={'uk_k_beskr'}></GeoFaWidget>
                        </IonCol>
                    </IonRow>
                }

                {featureProperties.laengde &&
                    <IonRow>

                        <GeoFaWidget properties={featureProperties}
                                     label={t('laengde')}
                                     typeCodes={[]}
                                     field={'laengde'}
                                     translateValue={true}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {featureProperties.folde_link &&
                    <IonRow>
                        <IonCol class={styles.paddingLeftNull}>
                            {/* <div className={styles.label}>Folder</div> */}
                            <Link to={{pathname: featureProperties.folde_link}} target="_blank">
                                {/* <IonIcon name="book-outline"></IonIcon> */}
                                <Trans i18nKey="getFolder"></Trans>
                            </Link>
                            <br/>
                        </IonCol>
                    </IonRow>
                }

                {featureProperties.link &&
                    <IonRow>
                        <IonCol class={styles.paddingLeftNull}>
                            <Link to={{pathname: featureProperties.link}} target="_blank">
                                <Trans i18nKey="toHomepage"></Trans>
                            </Link>
                            <br/>
                        </IonCol>
                    </IonRow>
                }

                {featureProperties.tilgaeng_beskriv &&
                    <IonRow>
                        <GeoFaWidget properties={featureProperties}
                                     typeCodes={[5, 4411, 3012, 3091, 2012, 1302, 2092, 1291, 1012, 1222]}
                                     field={'tilgaeng_beskriv'}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {featureProperties.saeson_bem && <IonRow>
                    <GeoFaWidget label={t('canBeBooked')}
                                 properties={featureProperties}
                                 typeCodes={[3012, 4111]}
                                 translateValue={true}
                                 field={'book'}></GeoFaWidget>
                </IonRow>
                }

                {featureProperties.lang_beskr &&
                    <IonRow>
                        <GeoFaWidget properties={featureProperties}
                                     typeCodes={[]}
                                     field={'lang_beskr'}
                                     de_field={'d_l_beskr'}
                                     en_field={'uk_l_beskr'}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {/*
                {featureProperties.ansva_v &&
                    <IonRow>

                        <GeoFaWidget properties={featureProperties}
                                     label={t('maintainedBy')}
                                     typeCodes={[1061, 6052, 6062, 4191, 4411]}
                                     field={'ansva_v'}
                                     translateValue={true}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }
                */}

                {featureProperties.ansvar_org &&
                    <IonRow>

                        <GeoFaWidget properties={featureProperties}
                                     label={t('maintainedBy')}
                                     typeCodes={[1061, 6052, 6062, 4191, 4411]}
                                     field={'ansvar_org'}
                                     translateValue={false}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {featureProperties.kontak_ved &&
                    <IonRow>

                        <GeoFaWidget properties={featureProperties}
                                     label={t('contact')}
                                     typeCodes={[1061, 6052, 6062, 4191, 4411]}
                                     field={'kontak_ved'}
                                     translateValue={false}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {featureProperties.handicap &&
                    <IonRow>
                        <GeoFaWidget properties={featureProperties}
                                     typeCodes={[4411, 3012, 3091, 2012, 1302, 2092, 5072]}
                                     field={'handicap'}
                                     translateValue={true}
                                     dangerous={true}></GeoFaWidget>
                    </IonRow>
                }

                {featureProperties.book && <IonRow>
                    <GeoFaWidget label={t('canBeBooked')}
                                 properties={featureProperties}
                                 typeCodes={[3012, 4111]}
                                 translateValue={true}
                                 field={'book'}></GeoFaWidget>
                </IonRow>
                }

                {featureProperties.betaling && <IonRow>
                    <GeoFaWidget label={t('payment')}
                                 properties={featureProperties}
                                 typeCodes={[3012, 1302]}
                                 translateValue={true}
                                 field={'betaling'}></GeoFaWidget>
                </IonRow>
                }

                {showMap &&
                    <IonRow className={styles.mapContainer}>
                        <FacilityMap objectId={oI}></FacilityMap>
                    </IonRow>
                }

            </IonGrid>

        </>
    )

}

export default Facility;
