import * as React from "react";
import {useContext, useEffect, useRef} from "react";
import {
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonFabList,
    IonHeader,
    IonIcon,
    IonImg,
    IonToolbar
} from "@ionic/react";

import ToenderBrandSVG from "../../assets/ToenderSkib.svg";
import vlineSVG from "./vline.svg";
import deSVG from "./de.svg";
import dkSVG from "./dk.svg";
import gbSVG from "./gb.svg";
import MapContext from "../../contexts/MapContext";

import classNames from 'classnames';

import styles from './Header.module.css';
// import i18n from "i18next";
import {useParams} from "react-router-dom";

import {AppConfig} from "../../utils/AppConfig";
import {Trans, useTranslation} from "react-i18next";
import getExtendedMunicipalitydData from "../../services/kommuner/ExtendedMunicpalities";
import getExtendedMunicipalitydName from "../../services/kommuner/ExtendedMunicpalities";

interface IParams {
    kommuneGUID?: string
}

const Header: React.FC = () => {

    const {getState} = useContext(MapContext);

    let { kommuneGUID } = useParams<IParams>();

    const modal = useRef<HTMLIonModalElement>(null);

    const { i18n } = useTranslation();
    // const currentLanguage = t(`languages.${}`);


    const dismiss = () => (event: React.MouseEvent<HTMLIonItemElement>): void => {
        modal.current?.dismiss();
    };

    useEffect(() => {
    }, [])

    function setLanguage(lang: string) {
        i18n.changeLanguage(lang);
    }

    return (
        <IonHeader className={styles.header}>
            <IonToolbar className={styles.toolbar}>
                    <IonButtons slot="start" className={styles.buttons}>
                        {getExtendedMunicipalitydName(kommuneGUID) === undefined ?
                            <IonImg src={ToenderBrandSVG}
                                    alt="Tønder Kommune"
                                    className={classNames(styles.brand)}>
                            </IonImg> : undefined
                        }
                        <IonImg src={vlineSVG} alt="line" className={styles.line}></IonImg>
                        {getExtendedMunicipalitydName(kommuneGUID) ?
                            <div> Friluftsliv i {getExtendedMunicipalitydName(kommuneGUID)} Kommune</div>
                            : null
                        }
                        {getExtendedMunicipalitydName(kommuneGUID) === undefined ?
                            <div className={styles.title}>
                            <Trans i18nKey="title"></Trans>
                        </div> : undefined
                        }
                        {getState().showFilterModal}
                    </IonButtons>
            </IonToolbar>

            {AppConfig.languageOn && <><IonContent className={styles.fake}>
                <IonFab slot="fixed" vertical="top" horizontal="end">
                    <IonFabButton size="small" aria-label="Language">
                        {['da', 'da-DK'].includes(i18n.language) && <IonIcon icon={dkSVG} className={styles.lang}></IonIcon>}
                        {['de', 'de-DE', 'de-CH', 'de-AT', 'de-LI', 'de-LU'].includes(i18n.language)  && <IonIcon icon={deSVG} className={styles.lang}></IonIcon>}
                        {['en', 'en-GB', 'en-US'].includes(i18n.language) && <IonIcon icon={gbSVG} className={styles.lang}></IonIcon>}
                    </IonFabButton>
                    <IonFabList side="start">
                        <IonFabButton onClick={() => setLanguage('da-DK')} aria-label="Dansk">
                            <IonIcon icon={dkSVG} className={styles.lang}></IonIcon>
                        </IonFabButton>
                        <IonFabButton onClick={() => setLanguage('de-DE')} aria-label="Deutsch">
                            <IonIcon icon={deSVG} className={styles.lang}></IonIcon>
                        </IonFabButton>
                        <IonFabButton onClick={() => setLanguage('en-GB')} aria-label="English">
                            <IonIcon icon={gbSVG} className={styles.lang}></IonIcon>
                        </IonFabButton>
                    </IonFabList>
                </IonFab>
            </IonContent>

                {/*
            <IonModal ref={modal}>
                <div className="wrapper">
                    <h1>Dialog header</h1>
                    <IonList lines="none">
                        <IonItem button={true} detail={false} onClick={dismiss('da')}>
                            <IonIcon icon={dkSVG}></IonIcon>
                            <IonLabel>Dansk</IonLabel>
                        </IonItem>
                        <IonItem button={true} detail={false} onClick={dismiss('de')}>
                            <IonIcon icon={deSVG}></IonIcon>
                            <IonLabel>Deutsch</IonLabel>
                        </IonItem>
                        <IonItem button={true} detail={false} onClick={dismiss('en')}>
                            <IonIcon icon={gbSVG}></IonIcon>
                            <IonLabel>English</IonLabel>
                        </IonItem>
                    </IonList>
                </div>
            </IonModal>
            */}
                </>
            }
        </IonHeader>
    )
}

export default Header;
