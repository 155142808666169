import {EGeoType, IconService} from "../../services/IconService";

import FacilityIcon from "../FacilityIcon/FacilityIcon";
import {Feature} from "ol";
import React, {useContext, useEffect, useState} from "react";
import MapContext from "../../contexts/MapContext";
import {Geometry} from "ol/geom";

import styles from './FacilitiyItem.module.css';
import i18n from "i18next";
import getSimpleLanguageCode from "../../services/Helpers";

interface FacilityItemProps {
    objectId?: string,
    onClick?: () => void,
    className?: {},
    loading?: boolean
}

const FacilityItem: React.FC<FacilityItemProps> = ({
                                                       objectId,
                                                       onClick,
                                                       loading
                                                   }) => {

    const {getState} = useContext(MapContext);
    const iconService = new IconService();

    const lang = i18n.language;
    let ftr: Feature<Geometry> | undefined;

    const [f, setF] = useState<string>('')
    const [l, setL] = useState<string>('')
    const [n, setN] = useState<string>('')
    const [geoType, setGeoType] = useState<EGeoType>(EGeoType.Point)

    const [theme, setTheme] = useState<any>()

    const [ldng, setLdng] = useState<boolean | undefined>(true)

    useEffect(() => {
        setLdng(loading)
    }, [loading]);

    // TODO: Let i18n do that
    useEffect(() => {
        localizeLabel(lang)
    }, [lang, theme]);

    function localizeLabel(language: string) {
        if (theme) {
            if (getSimpleLanguageCode(i18n.language) === 'da') {
                setF(theme?.label || '***');
            } else if (getSimpleLanguageCode(i18n.language) === 'de') {
                setF(theme?.label_de || theme?.label || '***');
            } else {
                setF(theme?.label_gb || theme?.label || '***');
            }
        }
    }

    /*
    We check whether objectId is set and not null.
    Sometimes an object gets stuck in the modals
     */
    useEffect(() => {

        let oI: string;

        if (!objectId) {
            oI = getState().featureIdInMapOverlay;
        } else {
            oI = objectId
        }

        if (oI) {

            const features: Feature[] = getState().allFeatures;

            let filteredFeature = features.filter(
                o => o.getProperties().objekt_id === oI
            )

            if (filteredFeature.length > 0) {
                ftr = filteredFeature[0]
            }

            let properties = ftr?.getProperties() || {}

            if (properties?.facil_ty_k) {
                setTheme(iconService.getTheme(properties?.facil_ty_k))
            }

            if (properties?.rute_ty_k) {
                setTheme(iconService.getTheme(properties?.rute_ty_k));
            }

            localizeLabel(lang)

            setN(properties?.navn);

            // TODO: Use the shortened method
            if (['de', 'de-DE', 'de-CH', 'de-AT', 'de-LI', 'de-LU'].includes(i18n.language) && properties.navn_d) {
                setN(properties.navn_d);
            } else if (['en', 'en-GB', 'en-US'].includes(i18n.language) && properties.navn_uk) {
                setN(properties.navn_uk);
            }

            // Points
            if (properties.temakode === 5800) {
                setL(properties?.facil_ty_k);
                setGeoType(EGeoType.Point);
            }

            // Lines
            if (properties.temakode === 5802) {
                setL(properties?.rute_ty_k);
                setGeoType(EGeoType.Line);
            }

            // Polygons
            if (properties.temakode === 5801) {
                setGeoType(EGeoType.Polygon);
            }

        } else {
            console.log('NO oI');
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.bottom}>
                <div className={styles.top}>
                    <FacilityIcon className={styles.FriluftsIcon}
                                  geoType={geoType}
                                  classId={l}
                                  loading={ldng}
                                  fontSize={"35px"}></FacilityIcon>
                </div>
                <div className={styles.Bc}>
                    <b>{n}</b><br/>
                </div>
                <i className={styles.facilityType}>{f}</i>
            </div>

        </div>
    )
}

export default FacilityItem;
