import * as React from "react";
import {useContext, useEffect, useState} from "react";

import styles from './FilterListItem.module.css';
import {EGeoType, IBaseTheme, IconService} from "../../../services/IconService";
import {IThemegroup} from "../../../interfaces/ICategoryItem";
import FacilityIcon from "../../FacilityIcon/FacilityIcon";
import MapContext from "../../../contexts/MapContext";
import i18n from "i18next";
import getSimpleLanguageCode from "../../../services/Helpers";

interface ITypedTheme {
    geoType: EGeoType,
    theme?: IBaseTheme,
    category: string
}

interface FilterListItemProps {
    category: (IThemegroup | number)
    onClick?: () => void;
}

/**
 * TODO: This thing needs simplification
 * @param category
 * @param onClick
 * @constructor
 */
const FilterListItem: React.FC<FilterListItemProps> = ({category,
                                                           onClick}) => {

    // Main label
    const [label, setLabel] = useState<string>();

    // Themes in a row
    const [themes, setThemes] = useState<ITypedTheme[]>([]);

    const staticClassName = 'line';
    let gT;

    const iconService = new IconService();

    /**
     * Set label by language.
     */
    function getLabel(theme: IBaseTheme | undefined): string {

        const sL = getSimpleLanguageCode(i18n.language);

        if (theme) {
            if (sL === 'da') {
                return theme.label || 'No Theme'
            } else if (sL === 'de') {
                return theme.label_de || theme.label
            } else {
                return theme.label_gb || theme.label
            }
        } else {
            return '<>';
        }
    }

    useEffect(() => {

        if (typeof category === 'number') {
            // A single theme
            let theme = iconService.getTheme(category);
            let geoType = iconService.getGeotype(category)
            setThemes([{
                theme: theme,
                geoType: geoType,
                category: String(category),
            }])
            setLabel(getLabel(theme));
        } else {
            // A grouped theme
            setLabel(category.label);
            let enrichedList:ITypedTheme[] = category.themes.map((num) => ({
                theme: iconService.getTheme(num),
                geoType: iconService.getGeotype(num),
                category: String(num)
            }));
            setThemes(enrichedList);
        }
    }, []);

    function getIcons() {
        return themes.map((theme) =>
            <FacilityIcon className={styles.FriluftsIcon}
                          geoType={theme.geoType}
                          classId={theme.category}
                          key={`i-${theme.theme?.label}`}
                          k={theme.theme?.label}
                          fontSize={"25px"}></FacilityIcon>
        )
    }

    function _getIcons() {
        themes.forEach((t) => {
            // console.log('->', t.theme?.label, t.category);
        })
        return <b>x</b>
    }

    // TODO: Fix this
    const dynamicClassName = `geofa-line-${gT}`;

    // Combine the class names us   ing template literals
    const combinedClassName = `${staticClassName} ${dynamicClassName}`;

    return (
        <div className={styles.container}>
            <span className={combinedClassName}></span>
            <>{label}</>
            <div className={styles.top}>
                {getIcons()}
            </div>
        </div>
    )

}

export default FilterListItem;
