import * as React from "react";
import {useContext} from "react";

import SingleFacilityBadge from "./SingleFacilityBadge";
import MultipleFacilityBadge from "./MultipleFacilityBadge";
import MapContext from "../../contexts/MapContext";

/**
 * From MapTab on click of feature(s);
 * From List Modal -> Object
 * @constructor
 */
const FacilityBadge: React.FC = ({}) => {

    const {getState} = useContext(MapContext);

    return <>
        {getState().tappedFeatures.length===1 && <SingleFacilityBadge objectId={getState().tappedFeatures[0]?.getProperties().objekt_id}></SingleFacilityBadge> }
        {getState().tappedFeatures.length>1 && <MultipleFacilityBadge></MultipleFacilityBadge> }
    </>
}

export default FacilityBadge;
