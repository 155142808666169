import {IonIcon} from "@ionic/react";
import {cafeOutline, informationOutline} from "ionicons/icons";
import * as React from "react";
import {useContext, useEffect, useState} from "react";

import styles from "./AboutIconWithLabel.module.css";
import MapContext from "../../contexts/MapContext";
import AboutLabel from "./AboutLabel";

const AboutIconWithLabel: React.FC = () => {

    const [filterSize, setFilterSize] = useState<number>(0);

    const {getState} = useContext(MapContext);

    useEffect(() => {

        let themeFilter = getState().themeFilter;
        setFilterSize(getState().themeFilter.length)

    }, [getState, getState().themeFilter])

    return (
        <>
            <div className={styles.container}>
                { getState().themeFilter.length > 0 &&
                    <div className={styles.filterBadge}>{filterSize}</div> }
                <IonIcon aria-hidden="true" icon={informationOutline}/>
            </div>
            <AboutLabel></AboutLabel>
        </>

    )
}

export default AboutIconWithLabel;
