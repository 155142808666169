/*
We wrap the contents of the Slide in order to hide the content before its in the viewport.
 */
import * as React from "react";
import {useContext, useEffect, useRef} from "react";
import {useSwiper, useSwiperSlide} from "swiper/react";
import styles from "./MultipleFacilityBadge.module.css";
import FacilityItem from "../FacilityItem/FacilityItem";
import Facility from "../Facility/Facility";
import * as ol from "ol";
import MapContext from "../../contexts/MapContext";

interface MultipleFacilityBadgeFooProps {
    feature: ol.Feature
}

const SwiperSlideInnerWrapper: React.FC<MultipleFacilityBadgeFooProps> = ({feature}) => {

    const swiperSlide = useSwiperSlide();
    const className = `${swiperSlide.isActive ? styles.active : styles.inactive}`;
    const slideRef = useRef<HTMLDivElement>(null);

    const {getState} = useContext(MapContext);
    const swiper = useSwiper();

    useEffect(() => {
        if (swiperSlide?.isActive) {
            getState().setFeatureIdInMapOverlay(feature.getProperties().objekt_id);
            getState().tappedFeatures.map((f:ol.Feature) => f.changed())
        }
    }, [JSON.stringify(swiperSlide)])

    function dynamicStyleFunction() {
        if (swiperSlide.isActive) {
            return styles.active
        } else {
            return styles.inactive
        }
    }

    function handleCallback(b: boolean) {
        // TODO: ???
    }

    return (
        <div className={className} ref={slideRef}>
            <FacilityItem objectId={feature.getProperties().objekt_id}></FacilityItem>
            {/* className={dynamicStyleFunction()}  */}
            <Facility objectId={feature.getProperties().objekt_id} parentCallback={handleCallback}></Facility>
        </div>
    )
}

export default SwiperSlideInnerWrapper;
