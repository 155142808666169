import {ICategoryItem, IThemegroup} from "../../interfaces/ICategoryItem";

function flattenThemeFilter(
    category: ICategoryItem,
    themeFilter: any, // (IThemegroup | number)[],
    groupIDs: number[] = []
) {

    return themeFilter.map((m: number) => {
        if (groupIDs.indexOf(m)> -1) {
            // we have a group, lets resolve it
            return category.themes.map((r: number | IThemegroup) => {
                if (typeof r === 'number') {
                } else {
                    // IThemegroup
                    return r.themes
                }
            }).filter(Boolean).flat() as number[] || [];
        } else {
            return m;
        }
    }).flat() as number[] || []

}

function flattenArray(arr: any[]): any[] {

    let result: any[] = [];

    if (Array.isArray(arr)) {
        arr.forEach((item) => {
            if (typeof item !== 'number') {
                result = result.concat(item.themes);
            } else {
                result.push(item);
            }
        });
    } else {
        console.error('Input is not an array');
    }

    return result;
}

export default flattenArray;
