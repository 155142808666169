import {ICategoryItem} from "../interfaces/ICategoryItem";
import {EGeoType} from "../services/IconService";

export const cats: ICategoryItem [] = [

    {
        label: 'Stier og ruter',
        label_de: 'Pfade und Routen',
        label_gb: 'Trails and routes',
        color: '#aaaaaa',
        geotype: EGeoType.Line,
        themes: [5, 3, 4, 8, 9]
    },
    {
        label: 'Vandaktiviteter',
        label_de: 'Aktivitäten am Wasser',
        label_gb: 'Water activities',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [1061, 6052, 6062, 4191, 4411]
    },
    {
        label: 'Overnatning',
        label_de: 'Übernachtungsmöglichkeiten',
        label_gb: 'Overnight accommodation',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [3012, 3031, 3071]
    },
    {
        label: '(Frilufts)aktiviteter',
        label_de: '(Outdoor)aktivitäten',
        label_gb: '(Outdoor) activties',
        color: '#b20d0d',
        geotype: EGeoType.Point,
        themes: [1022, 3091, 1201 , {
            number: 10000,
            label: 'Legepladser',
            label_de: 'Spielplätze',
            label_gb: 'Playgrounds',
            themes: [1031, 1041],
            pictogram: undefined
        }, 4111, 4222, 1191]
    },
    {
        label: '(Frilufts)seværdigheder',
        label_de: '(Outdoor)sehenswürdigkeiten',
        label_gb: '(Outdoor)sights',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [5102, 2012, {
            label: 'Natur- og kulturformidling',
            label_de: 'Natur- und Kulturvermittlung',
            number: 10001,
            themes: [1302, 2092],
            pictogram: undefined
        }, 5072, 1152]
    },
    {
        label: 'P, WC og vand',
        label_de: 'P, WC und Trinkwasser',
        label_gb: 'P, WC and potable water',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [1291, 1012, 1222]
    }

]
