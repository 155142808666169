import {EGeoType} from "./IconService";
import {AppConfig} from "../utils/AppConfig";
import {GeoJSON} from "ol/format";
import {Geometry} from "ol/geom";
import {Feature} from "ol";

const ENDPOINT_URL = AppConfig.apiBaseUrl

// Enum format, geoformat
interface IGeoFAQuery {
    q: string
    format?: string
    geoformat?: string
}

// TODO - remove doublette
interface IParams {
    kommuneGUID?: string
}

class GeoFAService {

    kommunenr: number | undefined = undefined;

    constructor(kommunenr: number) {
        this.kommunenr = kommunenr;
        console.log('geofaservice', this.kommunenr);
    }

    PHOTO_URL = AppConfig.photoBaseUrl;

    THEME_POINTS = "fkg.t_5800_fac_pkt";
    THEME_ROUTES = "fkg.t_5802_fac_li";
    THEME_POLYGONS = "fkg.t_5801_fac_fl";

    POINT_RETURN_FIELDS = [
        "objekt_id",
        "navn",
        "facil_ty",
        "facil_ty_k",
        "kommunekode",
        "geometri",
        "beskrivels", // TODO: move to DetailPage.tsx
        "lang_beskr", // TODO: move to DetailPage.tsx
        "foto_link",
        "geofafoto",
        "temakode"
    ]

    // The minimum set of values we need for all locations apart from the Detail View.
    MIN_FIELDS_5800 = [
        "geometri",
        "objekt_id",
        "facil_ty_k",
        "facil_ty",
        "temakode",
        "navn",
        "navn_d",
        "navn_uk",
        "off_kode"
    ]

    MIN_FIELDS_5802 = [
        "geometri",
        "objekt_id",
        "rute_ty_k",
        "beskrivels",
        "temakode",
        "navn",
        "off_kode"
    ]

    //
    MIN_FIELDS_5801 = [
        "geometri",
        "objekt_id",
        "facil_ty_k",
        "facil_ty",
        "beskrivels",
        "temakode",
        "navn",
        "off_kode"
    ]

    ALL_POINT_RETURN_FIELDS = [
        '*'
    ]

    getIN(keys: number[]): string {
        return `(${keys.join(', ')})`;
    }

    knr () {
        return this.kommunenr
    }

    wcP() {
        return '%20WHERE%20kommunekode=' + this.knr() + '%20OR%20beliggenhedskommune= ' +
            this.knr() + ' AND facil_ty_k IN ' + this.getIN(AppConfig.FACILITY_POINTS);
    }

    WHERE_CLAUSE_LINES = '%20WHERE%20kommunekode=' + this.knr() + '%20OR%20beliggenhedskommune= ' +
        this.knr(); // + '%20OR%20off_kode=' + AppConfig.off_kode;

    WHERE_CLAUSE_POLYGONS = '%20WHERE%20kommunekode=' + this.knr() + '%20OR%20beliggenhedskommune= ' +
        this.knr(); // + '%20OR%20off_kode=' + AppConfig.off_kode;


    /*
    lqP():string {
        const x =  this.POINT_RETURN_FIELDS.join(',') + '%20FROM%20' + this.THEME_POINTS + this.wcP();
        console.log('xx', x);

     */

    POINT_QUERY = {
        q: "SELECT%20" + 'foo',
        format: 'geojson',
        geoformat: 'geojson'
    }


    LINE_QUERY = {
        q: "SELECT%20" + this.MIN_FIELDS_5802.join(',') + '%20FROM%20' + this.THEME_ROUTES +  this.WHERE_CLAUSE_LINES,
        format: 'geojson',
        geoformat: 'geojson'
    }

    POLYGON_QUERY = {
        q: "SELECT%20" + this.MIN_FIELDS_5801.join(',') + '%20FROM%20' + this.THEME_POLYGONS + this.WHERE_CLAUSE_POLYGONS,
        format: 'geojson',
        geoformat: 'geojson'
    }

    public dictToURI(dict: object) {
        let s = "";
        Object.entries(dict).forEach(
            ([key, value]) => {
                s = String(s) + "&" + key + "=" + value;
            }
        );
        return s.substring(1);
    }

    public getPointsQueryString(): string {
        return this.dictToURI(this.POINT_QUERY);
    }

    public getLinesQueryString(): string {
        return this.dictToURI(this.LINE_QUERY);
    }

    public getPolygonsQueryString(): string {
        return this.dictToURI(this.POLYGON_QUERY);
    }

    public getPointsURL(): string {
        return ENDPOINT_URL + '?' + this.getPointsQueryString();
    }


    public getLinesURL(): string {
        return ENDPOINT_URL + '?' + this.getLinesQueryString();
    }

    public getPolygonsURL(): string {
        return ENDPOINT_URL + '?' + this.getLinesQueryString();
    }


    public async getFeatures<TResponse>(type?: EGeoType, fields?: string[]): Promise<TResponse> {

        // Fallback to minimal
        let flds = fields || this.MIN_FIELDS_5800;

        let sql:string= '';

        if (type === EGeoType.Point) {
            if (AppConfig.FACILITY_POINTS && AppConfig.FACILITY_POINTS.length > 0) {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${this.knr()} AND facil_ty_k IN ${this.getIN(AppConfig.FACILITY_POINTS)}`;
            } else {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${this.knr()}`;
            }
        }

        if (type === EGeoType.Line) {
            if (AppConfig.FACILITY_LINES && AppConfig.FACILITY_LINES.length > 0) {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${this.knr()} AND rute_ty_k IN ${this.getIN(AppConfig.FACILITY_LINES)}`;
            } else {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${this.knr()}`;
            }
        }

        if (type === EGeoType.Line) {
            if (AppConfig.FACILITY_POLYGONS && AppConfig.FACILITY_POLYGONS.length > 0) {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${this.knr()} AND facil_ty_k IN ${this.getIN(AppConfig.FACILITY_POLYGONS)}`;
            } else {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${this.knr()}`;
            }
        }

        const QUERY= {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson',
            srs: AppConfig.srs
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {

                // Doing this by a query did not work, so we filter old school
                const rawFeatures: Feature<Geometry>[] = new GeoJSON().readFeatures(data);
                const ftrs: Feature<Geometry>[] = []

                rawFeatures.forEach((f) => {
                    if (f.getProperties().off_kode == 1) {
                        ftrs.push(f);
                    }
                });

                return ftrs as TResponse;
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /*
    Get all features within the given (now hardcoded) bbox and of a certain kommunenr.
    Right now we use it for 999 - Naturstyrelsen, which has facilities all over DK.
     */
    public async getFeaturesWithinBBOX<TResponse>(kommunenr?: number, fields?: string[]):Promise<TResponse>  {

        const flds = fields || this.MIN_FIELDS_5800;
        const kmk = kommunenr ? `kommunekode=${this.kommunenr} OR beliggenhedskommune=${this.kommunenr}` : '';

        // TODO: Unhardcode bbox #34 and put it into settings
        const sql = `SELECT ${flds.join(',')}
            FROM fkg.t_5800_fac_pkt
            WHERE ST_Intersects(
                geometri,
                ST_Transform(ST_SetSRID(ST_GeomFromGeoJSON('{
                    "type": "Polygon",
                    // TODO: Put into AppCponfig
                    "coordinates": [
                        [
                            [
              8.466627413933411,
              54.88033826612854
            ],
            [
              9.246846976576624,
              54.88125831765106
            ],
            [
              9.249092415514076,
              55.244496853818035
            ],
            [
              8.46177582338953,
              55.243564368513695
            ],
            [
              8.466627413933411,
              54.88033826612854
            ]
                        ]
                    ]
                }'), 4326), 25832)
            )
            ${kmk}`;

        const QUERY= {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson',
            srs: AppConfig.srs
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                const ftrs = new GeoJSON().readFeatures(data);
                //this.guidLookup.addFeatures(ftrs);
                return  ftrs as TResponse;
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /**
     * Return a feature from geoFA
     * @param objectID - an objects objekt_id
     * @param type  - the type in fkg
     */
    public async getSingleFeatureFromgeoFA<TResponse>(objectID: string, type?: EGeoType): Promise<TResponse> {

        const sql = `SELECT * FROM fkg.${type} WHERE objekt_id='${objectID}'`;
        // TODO: add geotype lookup
        const QUERY: IGeoFAQuery = {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson'
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        const resp = await fetch(queryString);
        const data = await resp.json();

        return data as TResponse;

    }

    public async getFeaturesForUIDList<TResponse> (ll: string[], type?: EGeoType, fields?: string[]): Promise<TResponse> {


        const quotedList = ll.map(item => `'${item}'`);
        const concatenatedString = quotedList.join(',');
        const sql= `SELECT * FROM fkg.t_5802_fac_li WHERE objekt_id IN (${concatenatedString})`;

        const QUERY: IGeoFAQuery = {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson'
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                return new GeoJSON().readFeatures(data) as TResponse;
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });

    }

    /**
     *
     * @param bbox The bounding box.
     * @param type The geotype
     * @param fields fields
     */
    public async getFeaturesForBBOX<TResponse>(bbox: number[], type?: EGeoType, fields?: string[]): Promise<TResponse> {

        const __table = 't_5800_fac_pkt';

        let flds = fields || ['navn', 'facil_ty', 'kommunekode', 'cvr_kode', 'cvr_navn', 'beskrivels', 'geometri'];

        let QUERY: IGeoFAQuery = {
            q: encodeURI(`${AppConfig.apiBaseUrl}?q=SELECT ${flds.join(',')} FROM fkg.${__table} WHERE ST_Intersects(geometri, ST_MakeEnvelope(${bbox[0]}, ${bbox[1]}, ${bbox[2]}, ${bbox[3]}, 25832));`),
            format: 'geojson,',
            geoformat: 'geojson'
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => data as TResponse)
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /*
    Let's get all photo connections to our object.
     */
    public async getPhotosForFeature<TResponse>(objekt_id: string): Promise<TResponse> {

        const sql = `SELECT * FROM t_7900_fotoforbindelse WHERE foto_objek='${objekt_id}'`;

        let QUERY: IGeoFAQuery = {
            q: encodeURI(sql)
        }

        let queryString = `${ENDPOINT_URL}?${this.dictToURI(QUERY)}`;

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                return new GeoJSON().readFeatures(data) as TResponse
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /*
    This rather exotic call returns a list of objekt_id's where photos are attached to.
    We need this for the height our modal opens upwards -- so we do not expose the headline.
    Contains duplicates
     */
    public async getFeatureIDsWithPhotosAttached<TResponse>(): Promise<TResponse> {

        const sql = `SELECT foto_objek FROM t_7900_fotoforbindelse WHERE kommunekode=${AppConfig.kommunenr} OR beliggenhedskommune=${AppConfig.kommunenr};`;

        const QUERY: IGeoFAQuery = {
            q: encodeURI(sql)
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY)

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => data as TResponse)
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }
}

export default GeoFAService;
