import {createContext} from "react";
import * as ol from "ol";

interface IFacilityMapContext {
    getFacilityMapState(): any
    mapObject: ol.Map
}

const defaultFacilityMapData: IFacilityMapContext = {
    getFacilityMapState(): any {},
    mapObject: new ol.Map({})
}

const FacilityMapContext = createContext<IFacilityMapContext>(defaultFacilityMapData);

export default FacilityMapContext;
