import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {I18nextProvider} from "react-i18next";
import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

const container = document.getElementById('root');
const root = createRoot(container!);

i18n.use(LanguageDetector).init({
    lng: 'da-DK',
    resources: {
        en: {
            translation: require('./locales/en.json')
        },
        da: {
            translation: require('./locales/da.json')
        },
        de: {
            translation: require('./locales/de.json')
        }
    },
    fallbackLng: 'da-DK',
    interpolation: {
        escapeValue: false
    },
    nonExplicitSupportedLngs: true
});

root.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals(console.log);

/*
root.render(
  /*<React.StrictMode>*/
  /*</React.StrictMode>
);*/
