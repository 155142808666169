import * as React from "react";
import {useState} from "react";
import {IonLabel} from "@ionic/react";

import styles from "./FilterLabel.module.css";
import {Trans} from "react-i18next";

const FilterLabel: React.FC = () => {

    const [label, setLabel] = useState<string>("FILTER");

    return <IonLabel className={styles.label}>
        <Trans i18nKey="filter"></Trans>
    </IonLabel>

}
export default FilterLabel;
