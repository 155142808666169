import {IonIcon} from "@ionic/react";
import FilterLabel from "../FilterLabel";
import * as React from "react";
import {useContext, useEffect, useState} from "react";

import styles from "./FilterIconWithLabel.module.css";
import MapContext from "../../../contexts/MapContext";

import filterSVG from "./filter.svg"

const FilterIconWithLabel: React.FC = () => {

    const [filterSize, setFilterSize] = useState<number>(0);

    const {getState} = useContext(MapContext);

    useEffect(() => {

        let themeFilter = getState().themeFilter;
        setFilterSize(getState().themeFilter.length)

    }, [getState, getState().themeFilter])

    return (
        <>
        <div className={styles.container}>
            { getState().themeFilter.length > 0 &&
            <div className={styles.filterBadge}>{filterSize}</div> }
            <IonIcon aria-hidden="true" icon={filterSVG}/>
        </div>
            <FilterLabel></FilterLabel>
        </>

    )
}

export default FilterIconWithLabel;
