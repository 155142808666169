import * as React from "react";
import {
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
} from "@ionic/react";

import styles from './MunicipalityListPage.module.css';
import {EXTENDEDMUNICIPALITIES} from "../../services/kommuner/ExtendedMunicpalities";

const URL_BASE:string = 'https://friluftskort.nordiq-gis.dk/kommune/';

function openLinkInNewTab(url:string) {
    window.open(url, '_blank');
}

const MunicpalityListPage: React.FC = () => {

    const extendedMunicipalitiesList = Object.keys(EXTENDEDMUNICIPALITIES)
        .map((guid) => ({
            guid,
            kommunenr: EXTENDEDMUNICIPALITIES[guid].kommunenr,
            navn: EXTENDEDMUNICIPALITIES[guid].navn
        }))
        .sort((a, b) => a.navn.localeCompare(b.navn));

    console.log(extendedMunicipalitiesList);


    function click(muni: any):void {
        console.log()
        openLinkInNewTab(URL_BASE + muni.guid);
    }

    return (
        <IonPage>
            <IonContent>
                {extendedMunicipalitiesList.map(muni => (
                    <IonButton key={muni.guid} onClick={(x) => click(muni)}>
                        {muni.navn}
                    </IonButton>
                ))}
            </IonContent>
        </IonPage>
    )
}

export default MunicpalityListPage;
