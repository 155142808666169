import React, {useEffect, useMemo, useState} from 'react';
import {IonIcon, IonSpinner} from '@ionic/react';
import {EGeoType, IconService} from "../../services/IconService";

import styles from './FacilityIcon.module.css';

let iconS = new IconService();

interface FacilityIconProps {
    geoType: EGeoType,
    classId: string,
    k?: string,
    fontSize?: string,
    className?: any,
    id?: any,
    loading?: boolean | undefined
}

/*
Starts with showing a spinner and then when loaded the icon of the facility type
 */
const FacilityIcon: React.FC<FacilityIconProps> = ({
                                                       geoType,
                                                       classId,
                                                       k,
                                                       fontSize,
                                                       loading
                                                   }) => {

    const [l, setL] = useState<boolean | undefined>(true)

    const fs = fontSize || '120px';

    const getSvgSrcMemoized = useMemo(() => {
        return iconS.getSvgSrc(geoType, classId);
    }, [geoType, classId]);

    const getSvgPictogramColorMemoized = useMemo(() => {
        const m = iconS.getSvgColor(geoType, classId);
        console.log(m)
        return m;
    }, [geoType, classId]);


    useEffect(() => {
        if (loading)
            setL(loading);
    }, [loading]);

    function getColor():string {
        return getSvgPictogramColorMemoized || 'lightgray';
    }

    return (
        <>
            {loading ? <div className={styles.spinnerContainer} style={{ backgroundColor: getColor()}}><IonSpinner name='circles' color="light"
                                                                            className={styles.ionSpinner}></IonSpinner></div> : false }
            {!loading ? <IonIcon src={getSvgSrcMemoized} style={{fontSize: fs}}/> : false}
        </>
    )
};

export default FacilityIcon;
