import * as React from "react";
import {useContext, useEffect, useState} from "react";
import FacilityList from "./components/FacilityList/FacilityList/FacilityList";
import MapContext from "./contexts/MapContext";
import Facility from "./components/Facility/Facility";
import {IonButton, IonButtons, IonHeader, IonIcon, IonToolbar} from "@ionic/react";
import styles from "./ListModalContent.module.css";
import {arrowBackOutline, arrowDownOutline} from "ionicons/icons";
import FacilityListCategory from "./components/FacilityList/FacilityListCategory/FacilityListCategory";
import FacilityItem from "./components/FacilityItem/FacilityItem";
import {Trans} from "react-i18next";

/**
 * Stuff we show in the list modal that can contain a single object view as well.
 * @constructor
 */
const ListModalContent: React.FC = () => {

    const {getState} = useContext(MapContext);
    const [st, setSt] = useState<string>('list');

    useEffect(() => {
        setSt(getState().listModalState)
    }, [getState, getState().listModalState]);

    useEffect(() => {
        setSt(getState().listModalState)
    }, [getState, getState().selectedObjectId]);

    /*
    Close the modal.
     */
    function close() {
        getState().setShowListModal(false);
    }

    function switchToList() {
        getState().setListModalState('list');
    }

    function handleCallback(b:boolean) {
        // TODO: ????
    }

    return (
        <>
            {st === 'longlist' &&
                <div>
                    <IonHeader className={styles.header}>
                        <IonToolbar className={styles.toolbar}>
                            <IonButtons slot="start">
                                <IonButton onClick={switchToList}>
                                    <IonIcon icon={arrowBackOutline}></IonIcon>
                                    <Trans i18nKey={'back'}></Trans>
                                </IonButton>
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton onClick={close}>
                                    <IonIcon icon={arrowDownOutline}></IonIcon>
                                    <Trans i18nKey={'close'}></Trans>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <FacilityListCategory full={true} cat={getState().selectedLongListCategory}></FacilityListCategory>
                </div>
            }
            {st === 'list' && getState().showListModal &&
                <div>
                    <IonHeader className={styles.header}>
                        <IonToolbar className={styles.toolbar}>
                            <IonButtons slot="end">
                                <IonButton onClick={close}>
                                    <IonIcon icon={arrowDownOutline}></IonIcon>
                                    <Trans i18nKey={'close'}></Trans>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <FacilityList></FacilityList>
                </div>
            }
            {st === 'object' &&
                <div>
                    <IonHeader className={styles.header}>
                        <IonToolbar className={styles.toolbar}>
                            <IonButtons slot="start">
                                <IonButton onClick={switchToList}>
                                    <IonIcon icon={arrowBackOutline}></IonIcon>
                                    <Trans i18nKey={'back'}></Trans>
                                </IonButton>
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton onClick={close}>
                                    <IonIcon icon={arrowDownOutline}></IonIcon>
                                    <Trans i18nKey={'close'}></Trans>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <div className={styles.mapOverLayFixer}>
                        <FacilityItem objectId={getState().selectedObjectId}></FacilityItem>
                    </div>
                    <Facility isObject={true} objectId={getState().selectedObjectId} parentCallback={handleCallback}></Facility>
                </div>
            }
        </>
    )
}

export default ListModalContent;
