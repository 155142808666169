import * as React from "react";
import {useRef} from "react";
import {IonLabel, IonModal} from "@ionic/react";
import {Trans} from "react-i18next";

interface ListLabelProps {
}

const ListLabel: React.FC<ListLabelProps> = () => {

    const modal = useRef<HTMLIonModalElement>(null);

    return (
        <>
            <IonLabel>
                <Trans i18nKey="list"></Trans>
            </IonLabel>
            <IonModal
                ref={modal}
                initialBreakpoint={0.1}
                breakpoints={[0, 0.1, 0.5, 0.75]}
                handleBehavior="cycle"
            >
                MODAL
            </IonModal>
        </>
    );
}

export default ListLabel;
