import * as React from 'react'
import {AppConfig} from "../../../utils/AppConfig";
import {ICategoryItem} from "../../../interfaces/ICategoryItem";
import FacilityListCategory from "../FacilityListCategory/FacilityListCategory";

import styles from './FacilityList.module.css';

const FacilityList: React.FC = () => {

    const renderCategories = () => {
        return AppConfig.categories.map((c:ICategoryItem) =>
            <FacilityListCategory cat={c} key={c.label}></FacilityListCategory>
        )
    }

    return (
        <div className={styles.ionContent}>
            {renderCategories()}
        </div>
    )

}

export default FacilityList;
