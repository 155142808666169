import * as React from "react";
import {useContext, useEffect, useState} from "react";
import * as ol from "ol";
import {IonChip, IonIcon, IonLabel} from "@ionic/react";

import {ICategoryItem, IThemegroup} from "../../../interfaces/ICategoryItem";
import MapContext from "../../../contexts/MapContext";
import FacilityItem from "../../FacilityItem/FacilityItem";
import {arrowForwardOutline} from "ionicons/icons";

import styles from './FacilityListCategory.module.css'
import i18n from "i18next";
import getSimpleLanguageCode from "../../../services/Helpers";

interface FacilityListCategoryProps {
    cat: ICategoryItem,
    full?: boolean          // Set true to show full (long) list
}

const FacilityListCategory: React.FC<FacilityListCategoryProps> = ({
                                                                       cat, full}) => {

    const {getState} = useContext(MapContext);
    const [fiVP, setFiVP] = useState<ol.Feature[]>([]);

    /*
    We need to update this component quite frequently due to changes in the filter and map extent.
     */
    function update() {

        setFiVP([])

        let themeFilter = getState().themeFilter;
        let ll: ol.Feature[] = [];

        let nt = cat.themes.map((r: number | IThemegroup) => {
            if (typeof r === 'number') {
                return r
            } else {
                return r.themes
            }
        })

        if (nt.flat().length > 0) {
            getState().featuresInViewport.forEach((f: ol.Feature) => {
                let props = f.getProperties();
                if ([5800, 5801].includes(props.temakode)) {
                    // Points and Polygons
                    if (nt.flat().includes(props.facil_ty_k)) {
                        if (nt.flat().includes(props.facil_ty_k) || themeFilter.length === 0) {
                            ll.push(f)
                        }
                    }
                } else {
                    // Lines
                    if (cat.themes.indexOf(f.getProperties().rute_ty_k) > -1) {
                        if (themeFilter.indexOf(f.getProperties().rute_ty_k) > -1 || themeFilter.length === 0) {
                            ll.push(f)
                        }
                    }
                }
            });
            setFiVP(ll)
        } else {
            // console.log('Themes length == 0');
        }

    }

    /**
     * On initial load.
     */
    useEffect(() => {
        update()
    }, [getState]);

    /*
    On pan zoom of the map.
     */
    useEffect(() => {
        update();
    }, [getState, JSON.stringify(getState().featuresInViewport)]);

    /**
     * Theme Filter changed
     */
    useEffect(() => {
        update();
    }, [getState, JSON.stringify(getState().themeFilter)]);

    function goToDetail(f: ol.Feature) {
        // Not very elegant - but it works
        // getState().setSelectedObjectId(f.getProperties().objekt_id) // TODO: kill ?
        getState().setFeatureIdInMapOverlay(f.getProperties().objekt_id)
        getState().setListModalState('object')
    }

    function getFeatures() {
        let fff;
        if (fiVP.length > 3 && !full) {
            fff = fiVP.slice(0, 3);
        } else {
            fff = fiVP;
        }
        return fff.map((f: ol.Feature) =>
            <div onClick={() => goToDetail(f)} key={f.getProperties().objekt_id}>
                <FacilityItem objectId={f.getProperties().objekt_id}
                              key={f.getProperties().objekt_id}></FacilityItem>
            </div>
        );
    }

    function goToCompleteList() {
        getState().setListModalState('longlist')
        getState().setSelectedLongListCategory(cat)
    }

    function getIonClass() {
        if (full)
            return styles.ionContent;
        else {

        }
    }
    function getCatLabel(cat:ICategoryItem):string {
        if (getSimpleLanguageCode(i18n.language) === 'da') {
            return cat.label
        } else if (getSimpleLanguageCode(i18n.language) === 'de') {
            return cat.label_de || cat.label
        } else {
            return cat.label_gb || cat.label
        }
    }

    return <>
        {fiVP.length > 0 &&
            <>
                <div className={getIonClass()}>
                    <div className={styles.categoryHeadline}>{getCatLabel(cat)}</div>
                    <div className={styles.features}>{getFeatures()}</div>
                </div>
                <div className={styles.mm}>
                    {fiVP.length > 3 && !full &&
                            <div className="ion-justify-content-end">
                                <IonChip onClick={goToCompleteList} className={styles.chip}>
                                    <IonLabel>+ {fiVP.length - 3} {getCatLabel(cat)}</IonLabel>
                                    <IonIcon icon={arrowForwardOutline}></IonIcon>
                                </IonChip>
                                <span className={styles.cls}></span>
                            </div>
                    }
                </div>
            </>
        }
    </>
};

export default FacilityListCategory;
